import React from 'react';
import '../index.css';
import "antd/dist/antd.css";

import { Layout, Menu } from 'antd';
import { Button } from 'antd';
import {Avatar,Popover } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  BookOutlined,
  TeamOutlined,
  ProfileOutlined
} from '@ant-design/icons';

import baseurl from '../public/baseurl';
import {request} from '../public/util/helper';
import Userguanli from '../public/model/userguanli';
import Liveguanli from '../public/model/liveguanli';
import { localStorageclear } from '../public/util/localStorageutil';

const { Header, Sider, Content ,Footer} = Layout;
// const { confirm } = Modal;


class admin extends React.Component {
    constructor(props){
        super();
        this.state={
            menuitems:null,
            collapsed: false,
            comment:null,
            user:{},
          };
        this.loadmenuf=this.loadmenuf.bind(this);
        this.toggle=this.toggle.bind(this);
        this.Itemclick=this.Itemclick.bind(this);
        this.loaduserf=this.loaduserf.bind(this);
        this.loginout=this.loginout.bind(this);
    }
    componentDidMount() {
      this.loaduserf();
      this.loadmenuf();
     }
    Itemclick(item){
      let selectkey=item.key;
      switch(selectkey){
        case "1":
          console.log(111)
          this.setState({
            comment:<Userguanli parentprops={this.props}/>,
          });
          break;
        case "2":
          this.setState({
            comment:<Liveguanli parentprops={this.props}/>,
          });
          break;
        default:
          break;
      }
    }
    loaduserf(){
      // 加载当前登录用户
      request({
        url:baseurl.admin.userinf,
        method:'post',
        props:this.props,
      }).then((res)=>{
        if(res){
          this.setState({
            user:res,
            // 设置初始化用户列表
            comment:<Userguanli parentprops={this.props}/>,
          });
        }
      });
    }
    loginout(){
      request({
        url:baseurl.login.loginout,
        method:'post',
        props:this.props,
      }).then(()=>{
        localStorageclear();
      });
    }
    loadmenuf(){
        let items=[
          {
            type: 'group',
            key: 'sub1',
            label: '用户',
            icon: <UserOutlined />,
            children: [
              {
                label: '用户管理',
                key: '1',
                icon: <TeamOutlined />,
              },
            ],
          },
          {
            type: 'group',
            key: 'sub2',
            label: '直播录制',
            icon: <BookOutlined />,
            children: [
              {
                label: '录制管理',
                key: '2',
                icon: <ProfileOutlined />,
              },
            ],
          },
        ]
        this.setState({
            menuitems:<Menu
            onClick={this.Itemclick}
            // style={{ width: "100%" }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={items}
          />
        });
    }
    toggle () {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      };
     render() {

         return(
             
      <Layout>
        <Sider className="layoutroot" trigger={null} collapsible collapsed={this.state.collapsed} >
        <div className="logo">
            <img src="/notelogo.png" width="60px" height="60px" alt="LOGO"/>
            <span style={{color:'white',fontSize:25}}>云笔记</span>
        </div>
        {this.state.menuitems}
        </Sider>
        <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} >
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            <Popover placement="rightTop" title={this.state.user.username} content=
            {<div>
            <Avatar size={30} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            <label style={{fontSize:'18px'}}>{'管理端'}</label>
            <p>{this.state.user.lastlogintime? '上次登录：'+this.state.user.lastlogintime:'欢迎使用云笔记'}</p>
            <Button style={{width:'100%',textAlign:"center"}} onClick={this.loginout}>退出登录</Button>
            </div>
            } trigger="click">
              <Avatar size={50} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            </Popover>
        </Header>
        <Content>
            {this.state.comment}
        </Content>
        <Footer>
        <h2 style={{textAlign:"center"}}><a href="https://beian.miit.gov.cn">皖ICP备2021012075号</a></h2>
        </Footer>
        </Layout>
    </Layout>
         );
     }

}
export default admin;