// import React from 'react';
// import ReactDom from 'react-dom';
// import './login.css';
// import "antd/dist/antd.css";
// import baseurl from '../public/baseurl';
// import {request} from '../public/util/helper';

// import { Form, Input, Button,Modal, message } from 'antd';
// import { UserOutlined, LockOutlined,BarcodeOutlined} from '@ant-design/icons';
// import {  localStorageSet } from '../public/util/localStorageutil';

// class Login extends React.Component{
//     constructor(props){
//         super();
//         this.state={
//           username:null,
//           remember:null,
//           visible:false,
//           confirmLoading:false,
//           title:'',
//           handleOkf:null,
//           modalconment:"",
//           newpwd:'',
//         }
//         this.onFinish=this.onFinish.bind(this);
//         this.onclickkaptcha=this.onclickkaptcha.bind(this);
//     }
//     componentDidMount(){
//       // if(localStorageGet("x-access-token"))this.props.history.replace({pathname:"/main"});
//       this.onclickkaptcha();
//     }
//     onclickkaptcha(){
//       let timestamp = (new Date()).valueOf();
//       let kaptcha = document.getElementById('kaptcha');
//       ReactDom.findDOMNode(kaptcha).src = baseurl.login.kaptcha+'?t='+timestamp
//     }
//     onFinish(values){
//       request({
//         url:baseurl.login.loginon,
//         method:'post',
//         data:{
//           "username":values.username,
//           "password":values.password,
//           "kaptcha":values.kaptcha
//         },
//         props:this.props,
//       }).then((res)=>{
//         if(res&&res.reset){
//           this.onclickkaptcha();
//           this.setState({
//             handleOkf:()=>{
//               this.setState({
//                 confirmLoading:true
//               });
//               request({
//                 url:baseurl.login.updatepwd,
//                 method:'post',
//                 data:{
//                   "username":values.username,
//                   "password":values.password,
//                   "newpwd":this.state.newpwd
//                 },
//                 props:this.props,
//               }).then((res)=>{
//                 this.setState({
//                   confirmLoading:false,
//                   visible:false,
//                   title:'',
//                   modalconment:"",
//                   newpwd:""
//                 });
//                 if(res && res.state){
//                   message.success("更新成功");
//                 }
//               });
//             },
//             visible:true,
//             title:'当前为重置密码，您需要设置新密码',
//             modalconment:<div>
//                           <label>新密码</label><Input
//                             prefix={<LockOutlined className="site-form-item-icon" />}
//                             onChange={({ target: { value }})=>{
//                               this.setState({
//                                 newpwd:value
//                               });
//                             }}
//                           />
//                           </div>
//           });
//         }
//         if(res&&res.token){
//           // localStorage.setItem('x-access-token',res.token);
//           localStorageSet("x-access-token",res.token);
//         }
//         if(!res){
//           this.onclickkaptcha();
//         }
        
//       });
//     }
//     render(){
//         return(
//           <div className='login-form-div'>
//             <div className='login-form-div-logo' >
//               <img src='/notelogo.png' width="60px" height="60px" alt="LOGO" />
//               <span>云笔记</span>
//             </div>
//             <Form
//               name="normal_login"
//               className="login-form"
//               initialValues={{ remember: true }}
//               onFinish={this.onFinish}
//             >
//             <Form.Item
//               name="username"
//               rules={[{ required: true, message: '请输入用户名' }]}
//             >
//               <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
//             </Form.Item>
//             <Form.Item
//               name="password"
//               rules={[{ required: true, message: '请输入密码' }]}
//             >
//               <Input
//                 prefix={<LockOutlined className="site-form-item-icon" />}
//                 type="password"
//                 placeholder="Password"
//               />
//             </Form.Item>
//             <img id="kaptcha" src={baseurl.login.kaptcha} alt="" onClick={this.onclickkaptcha}></img>
//             <label onClick={this.onclickkaptcha}>看不清楚？点击切换验证码</label>
//             <Form.Item
//               name="kaptcha"
//               rules={[{ required: true, message: '请输入验证码' }]}
//             >
//               <Input
//                 prefix={<BarcodeOutlined className="site-form-item-icon" />}
//                 placeholder="验证码"
//               />
//             </Form.Item>
//             {/* <Form.Item>
//               <Form.Item name="remember" valuePropName="checked" noStyle>
//                 <Checkbox>Remember me</Checkbox>
//               </Form.Item>
//             </Form.Item> */}

//             <Form.Item>
//               <Button type="primary" htmlType="submit" className="login-form-button">
//                 Log in
//               </Button>
//               {/* Or <a href="">register now!</a> */}
//             </Form.Item>
//         </Form>
//         <Modal
//         title={this.state.title}
//         visible={this.state.visible}
//         onOk={this.state.handleOkf}
//         confirmLoading={this.state.confirmLoading}
//         onCancel={()=>{
//           this.setState({
//             visible:false
//           });
//         }}
//       >
//         {this.state.modalconment}
//       </Modal>
//     </div>
//         );
//     }
// }

// export default Login;

import {  localStorageSet } from '../public/util/localStorageutil';
import React from 'react';
import baseurl from '../public/baseurl';
import {request} from '../public/util/helper';

function getParam(name, defaultValue){
  var href=window.location.href;
	var query = href.substring(href.indexOf("?")+1,href.length);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === name){return pair[1] + (pair[2] ? '=' + pair[2] : '');}
	}
	return(defaultValue === undefined ? null : defaultValue);
}

class Login extends React.Component{
  constructor(props){
      super();
      this.state={
        back: getParam("back", window.location.protocol+'//'+window.location.host+'/main'),
        ticket: getParam("ticket",'') 
      }
      this.doLoginByTicket=this.doLoginByTicket.bind(this);
      this.goSsoAuthUrl=this.goSsoAuthUrl.bind(this);
    }
    componentDidMount(){
      if(this.state.ticket) {
        this.doLoginByTicket(this.state.ticket);
      } else {
        this.goSsoAuthUrl();
      }
      console.log('获取 back 参数：', this.state.back)
      console.log('获取 ticket 参数：', this.state.ticket)
    }
    doLoginByTicket(ticket){
      request({
        url:baseurl.sso.checkTicket,
        method:'post',
        data:{
          ticket:ticket
        },
        props:this.props,
      }).then(res=>{
        if(res&&res.token){
            // localStorage.setItem('x-access-token',res.token);
            localStorageSet("x-access-token",res.token);
          }
      })
    }
    goSsoAuthUrl(){
      request({
        url:baseurl.sso.getSsoAuthUrl,
        method:'post',
        props:this.props,
      }).then(res=>{
        let authurl=res.authurl+"?redirect="+window.location.href;
        window.location.href = decodeURIComponent(authurl);
      })
    }
    render(){
      return <div></div>
    }
}

export default Login;