import { NavBar, Icon,Drawer} from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css'; 
import React from 'react';
import './main.css';
import baseurl from '../../public/baseurl';
import { request } from '../../public/util/helper';
import '@wangeditor/editor/dist/css/style.css'

import "antd/dist/antd.css";
import { Menu } from 'antd';
import { Button } from 'antd';
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { Input,message ,Modal,Avatar,Popover } from 'antd';
import {encrypt,decrypt} from '../../public/util/desutil';
import {
  AppstoreOutlined, 
  FontSizeOutlined,
  PlusOutlined,
  RedoOutlined,
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UnorderedListOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { localStorageclear,localStorageGet } from '../../public/util/localStorageutil';

const { SubMenu } = Menu;
const { confirm } = Modal;

class mainview extends React.Component{
    constructor(props){
        super();
        this.state={
            editor:null,
            open: false,
            toolbarvisible:"none",
            refreshdisable:true,
            saveoreditdisable:true,
            modalvisible:false,
            Modalinoutvalue:"",
            collapsed: false,
            menuitems:"",
            isLoaded: false,
            token:null,
            user:{},
            data:{},
            mbconment:{
              conment:"欢迎使用云笔记。",
              title:"",
              fontstyle:{
                  fontSize:25,
                  color:'black',
              },
              version:0,
            },
            selectitemprops:null,
        }
        this.toggle=this.toggle.bind(this);
      this.baseinff=this.baseinff.bind(this);
      this.menuitemf=this.menuitemf.bind(this);
      this.itemclikf=this.itemclikf.bind(this);
      this.saveoreditf=this.saveoreditf.bind(this);
      this.TextAreachange=this.TextAreachange.bind(this);
      this.modalsetVisible=this.modalsetVisible.bind(this);
      this.newnotef=this.newnotef.bind(this);
      this.Modalinoutchange=this.Modalinoutchange.bind(this);
      this.titlechange=this.titlechange.bind(this);
      this.deleteitem=this.deleteitem.bind(this);
      this.showPromiseConfirm=this.showPromiseConfirm.bind(this);
      this.loginout=this.loginout.bind(this);
      this.updateconment=this.updateconment.bind(this);
    }
    componentDidMount(){
        this.baseinff();
    }

    onOpenChange = (...args) => {
        this.setState({ open: !this.state.open });
    }
    TextAreachange(value){
      let com=this.state.mbconment;
      com.conment=value;
      this.setState({
        mbconment:com,
      });
    }
      titlechange({ target: { value }}){
        let com=this.state.mbconment;
        com.title=value;
        this.setState({
          mbconment:com,
        });
    
      }
      Modalinoutchange({ target: { value }}){
        this.setState({
          Modalinoutvalue:value,
        });
    
      }
      saveoreditf(){
        let conmentsplit=encrypt(this.state.mbconment.conment);
          request({
            url:baseurl.main.saveconment,
            method:'post',
            data:{
              "key":this.state.selectitemprops.key,
              "title":this.state.mbconment.title,
              "conment":conmentsplit,//保存时将换行符转译
              "version":this.state.mbconment.version,
            },
            props:this.props,
          }).then((data)=>{
            if(data){
              if(Boolean(data.res)){
                let com=this.state.mbconment;
                com.version=data.version;
                this.setState({
                  mbconment:com,
                });
                message.success("保存成功！");
              }else{
                message.error("保存失败："+data.errormsg);
              }
            }
          });
      }
      toggle () {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      };
      showPromiseConfirm(f=()=>{}) {
        confirm({
          title: '删除',
          icon: <ExclamationCircleOutlined />,
          content: '你确定要删除吗?',
          onOk() {
            f();
          },
          onCancel() {},
        });
      }
      deleteitem(){
        if(!this.state.refreshdisable){
          request({
            url:baseurl.main.deleteconment,
            method:'post',
            data:{
              "key":this.state.selectitemprops.key
            },
            props:this.props,
          }).then((data)=>{
            if(data){
              if(Boolean(data.res)){
                this.baseinff();
                message.success("删除成功！");
                return true;
              }else{
                message.error("删除失败："+data.msg);
                return false;
              }
            }
          });
        }
      }
      itemclikf(item){
        if(item){
          this.setState({
            selectitemprops:item,
            refreshdisable:false,
            saveoreditdisable:false,
          });
          request({
            url:baseurl.main.getconmentforkey,
            method:'post',
            data:{
              "key":item.key
            },
            props:this.props,
          }).then((data)=>{
            if(data&&data.res){
              let decconment=data.conment? decrypt(data.conment):"";
              // let decconment=data.conment;
              let com=this.state.mbconment;
              com.conment=decconment;
              com.title=data.title;
              com.fontstyle=data.fontstyle;
              com.version=data.version;
              this.setState({
                mbconment:com,
              });
              this.state.editor.enable();
              this.updateconment(decconment);
            } 
          });
        }
    }
    updateconment(decconment){
      decconment=decconment.replace("<p><br></p>","").replaceAll("%%!%!%%!%","\n");
      // console.log(data.conment);
      // 全选
      this.state.editor.select([])
      // 删除选中内容 
      this.state.editor.deleteFragment()
      // this.state.editor.insertText(data.conment);
      this.state.editor.dangerouslyInsertHtml(decconment);
    }
    modalsetVisible(boolea){
      this.setState({
        modalvisible:boolea,
      });
    }
    newnotef(){
      if(this.state.Modalinoutvalue===""){
        message.warning("标题不能为空",1);
      }else{
        request({
          url:baseurl.main.newnote,
          method:'post',
          data:{
            "title":this.state.Modalinoutvalue
          },
          props:this.props,
        }).then((data)=>{
          if(data){
            if(Boolean(data.res)){
              this.baseinff();
            }else{
              message.error("新建失败"+data.errormsg);
            }
          }
        });
      this.setState({
        modalvisible:false,
        Modalinoutvalue:"",
      });
      }
      
    }
      menuitemf(data){
        let menuitems=data.menuitems;
        // let defaultSelectedKeys=menuitems[0].child[0].child[0].key;
        let defaultOpenKeys=menuitems[0].key;
        if(menuitems&&menuitems!==null&&menuitems.length!==0){
          let jsxcoment=<Menu
          onClick={this.itemclikf}
          // defaultSelectedKeys={[defaultSelectedKeys]}
          defaultOpenKeys={[""+defaultOpenKeys]}
          mode="inline"
        >
          {
            menuitems.map(menuitem => {
              return <SubMenu key={menuitem.key} icon={<AppstoreOutlined />} title={menuitem.title}>
                {
                    menuitem.child.map(month=>{
                      return <Menu.ItemGroup key={month.key} title={month.title}>
                      {
                          month.child.map(day=>{
                            return <Menu.Item icon={<ProfileOutlined />} key={day.key}>{day.title}</Menu.Item>
                          })
                     }
                    </Menu.ItemGroup>
                    })
                }
            </SubMenu>
            })
          }
          </Menu>;
          return jsxcoment;
    
        }
        
      };
      loginout(){
        request({
          url:baseurl.login.loginout,
          method:'post',
          props:this.props,
        }).then(()=>{
          localStorageclear();
        });
      }
      baseinff(){
        request({
          url:baseurl.main.baseinf,
          method:'post',
          props:this.props,
        }).then((data)=>{
          if(data){
            let com=this.state.mbconment;
            com.title="";
            com.conment="<p><span style='font-size: 32px; font-family: 华文楷体;'><em><strong>欢迎使用云笔记</strong></em></span></p>"
            this.setState({
              isLoaded: true,
              data: data,
              menuitems:this.menuitemf(data),
              user:data.user,
              refreshdisable:true,
              saveoreditdisable:true,
              mbconment:com
            });
            this.updateconment(com.conment);
          }
          
        }); 
          const {isLoaded } = this.state;
          if (!isLoaded) {
            this.setState({
              menuitems:<Menu key="1">
              <Menu.Item>菜单载入中。。。</Menu.Item>
            </Menu>
            });
          }
      }
    render(){
      // ----------------------- editor config -----------------------
      const editorConfig = {}
      editorConfig.placeholder = '请输入内容...';
      editorConfig.height='auto';
      editorConfig.onCreated = (editor) => {
        this.setState({ editor })
        editor.disable()
        // console.log(editor.getAllMenuKeys());
      }
      editorConfig.onChange = (editor) => {
        // console.log(editor.getHtml())
        this.TextAreachange(editor.getHtml());
      }
      editorConfig.MENU_CONF = {}
      editorConfig.MENU_CONF['uploadImage'] = {
        server: baseurl.file.imgfile, // 上传图片地址
        fieldName: 'file',
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 10 * 1024 * 1024, // 10M

        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,
        //base64LimitSize: 30 * 1024,// 30k 以下的转base64

        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/*'],
        headers: {
          'x-access-token':localStorageGet('x-access-token'),
        },
        // 单个文件上传成功之后
      onSuccess(file, res) {
        // console.log(`${file.name} 上传成功`, res)
        message.success("上传成功");
      },
      // 单个文件上传失败
      onFailed(file, res) {
          // console.log(`${file.name} 上传失败`, res)
          message.error("上传失败"+res);
      },
      // 上传错误，或者触发 timeout 超时
      onError(file, err, res) {
        message.error("上传出错"+err);
          // console.log(`${file.name} 上传出错`, err, res)
      },
      }
      // 继续补充其他配置~

      // ----------------------- editor content -----------------------
      // const defaultContent = [
      //   { type: 'paragraph', children: [{ text: 'class 组件 - 基本使用' }] },
      //   { type: 'paragraph', children: [{ text: '' }] },
      // ]

      const defaultHtml = '<p><span style="font-size: 32px; font-family: 华文楷体;"><em><strong>欢迎使用云笔记</strong></em></span></p>';

      // ----------------------- toolbar config -----------------------
      const toolbarConfig = {
          excludeKeys: [
            'fullScreen',
            'uploadVideo', // 排除菜单组，写菜单组 key 的值即可
        ]
      }
        return(
            <div>
            <div className='header' style={{}}>
                <img src="/notelogo.png" width="80px" height="70px" alt="LOGO"/>
                <span style={{color:'#00000',fontSize:25}}>云笔记</span>
                <Popover placement="topLeft" title={this.state.user.username} content=
                {<div>
                <Avatar size={30} src={this.state.user.avatarurl} />
                <label style={{fontSize:'18px'}}>{this.state.user.username+'的个人云笔记'}</label>
                <p>{this.state.user.lastlogintime? '上次登录：'+this.state.user.lastlogintime:'欢迎使用云笔记'}</p>
                <Button type="link" onClick={()=>{window.location.href = decodeURIComponent('https://sso.yiqiulove.cn/me')}} >个人中心</Button>
                <Button style={{width:'100%',textAlign:"center"}} onClick={this.loginout}>退出登录</Button>
                </div>
                } trigger="click">
                    <Avatar size={50} style={{ position:'absolute', right:'5px', top:'15px'}} src={this.state.user.avatarurl} />
                </Popover>
            </div>
            <NavBar icon={<Icon type="ellipsis" />} onLeftClick={this.onOpenChange}>{this.state.mbconment.title}</NavBar>
            <Toolbar
                editor={this.state.editor}
                defaultConfig={toolbarConfig}
                style={{ borderBottom: '1px solid #ccc' ,display:this.state.toolbarvisible}}
              />
            <Drawer
                className="my-drawer"
                style={{ minHeight: document.documentElement.clientHeight }}
                enableDragHandle
                contentStyle={{ color: '#A6A6A6' }}
                sidebar={this.state.menuitems}
                open={this.state.open}
                onOpenChange={this.onOpenChange}
                >
                <div>
                {/* {this.state.saveoreditflag? <div>
                <div className="conment_div" style={this.state.mbconment.fontstyle}>{conmentdiv}</div></div>:
                <div>标题：<Input value={this.state.mbconment.title} allowClear={true} size="large" onChange={this.titlechange}/>
                内容：
                <TextArea 
                autoSize={{ minRows: 20, maxRows: 40 }} 
                value={this.state.mbconment.conment.replace(/%%!%!%%!%/g,"\n")} 
                allowClear={true} 
                rows={20}
                onChange={this.TextAreachange}
                ></TextArea></div>}  */}
                <Editor
                    defaultConfig={editorConfig}
                    // defaultContent={defaultContent}
                    defaultHtml={defaultHtml}
                    mode="default"
                />
                </div>
                <Modal
                  title="起个简短的标题吧"
                  centered
                  visible={this.state.modalvisible}
                  onOk={() => this.newnotef()}
                  onCancel={() =>this.modalsetVisible(false)}
                  width={1000}
                >
                  <Input allowClear={true} value={this.state.Modalinoutvalue} onChange={this.Modalinoutchange}size="large"/>
                </Modal>
                <div className='bottombutton'>
                  <Popover placement="leftBottom" title='操作' content=
                  {<div>
                  <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={() => this.modalsetVisible(true)} size="large"/>
                  <label> </label>
                    <Button type="primary" shape="circle" icon={<RedoOutlined />} size="large" disabled={this.state.refreshdisable}
                      onClick={()=>{this.itemclikf(this.state.selectitemprops)}}
                    /><label> </label>
                    <Button type="primary"  shape="circle" icon={<CheckOutlined />} size="large" 
                    disabled={this.state.saveoreditdisable} onClick={()=>{this.saveoreditf()}}/>
                    <label> </label>
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} size="large" disabled={this.state.refreshdisable}
                      onClick={()=>{this.showPromiseConfirm(()=>this.deleteitem())}}
                    />
                    <Button type="primary" shape="circle" icon={<FontSizeOutlined />} size="large" disabled={this.state.refreshdisable}
                      onClick={()=>{
                        if(this.state.toolbarvisible==="none"){
                          this.setState({
                            toolbarvisible:"block"
                          });
                        }else{
                          this.setState({
                            toolbarvisible:"none"
                          });
                        }
                      }}
                    />
                  </div>
                  } trigger="click">
                  <Button type="primary" shape="circle" icon={<UnorderedListOutlined />} style={{position:'fixed',bottom:'50px',right:'30px'}} size='large'/>
                  </Popover>
                </div>
            </Drawer>
        </div>
        );
        
    }

}
export default mainview;