import React from 'react';
import "antd/dist/antd.css";
import baseurl from '../public/baseurl';
import {request} from '../public/util/helper';
import { decrypt } from '../public/util/desutil';

import { Card, Avatar, Space,Tooltip,Button, List ,Empty,Input,
     Modal,message,Comment,Skeleton,BackTop
    } from 'antd';
import { 
    DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined,EyeOutlined
} from '@ant-design/icons';
import {  localStorageSet ,localStorageGet} from '../public/util/localStorageutil';
import { Editor } from '@wangeditor/editor-for-react';

const { Meta } = Card;

class Share extends React.Component{
    constructor(props){
        super();
        this.state={
            conment:null,
            conmentheadershow:false,
            id:null,
            baseinfloading:true,
            heishareinitLoading:true,
            heishareloading:false,
            onclicklike:"",
            onclicknotelike:"",
            modalvisible:false,
            hersharecounm:3,
            userinfo:{
                username:"",
                img:'Gululu(6)',
                lasttime:'',
                like:0,
                dislike:0,
            },
            shareinfo:{
                title:'',
                createtime:"",
                username:"",
                like:0,
                dislike:0,
                view:0,
            },
            overdue:false,
            pwd:null,
            Sharelistdisplay:'none',
        }

        this.initf=this.initf.bind(this);
        this.getconment=this.getconment.bind(this);
        this.showconment=this.showconment.bind(this);
        this.loadhershare=this.loadhershare.bind(this);
    }
    getconment(){
        request({
            url:baseurl.share.getconment,
            method:'post',
            data:{
                id:this.props.match.params.key,
                pwd:this.state.pwd
            },
            props:this.props,
            }).then((data)=>{
            if(data){
                if(data.msg){
                    message.error(data.msg);
                }
                else if(data.res){
                    this.setState({modalvisible:false});
                    localStorageSet("note_basedate_conment"+this.props.match.params.key,data.res);
                    this.showconment(data.res)
                }
            }
            
            });
        
    }
    onclickhershare(){
        this.setState({Sharelistdisplay:this.state.Sharelistdisplay==='block'?'none':'block'});
        if(this.state.Sharelistdisplay==='none'){
            this.loadhershare();
        }
        
    }
    loadhershare(){
        request({
            url:baseurl.share.gethersharelist,
            method:'post',
            data:{
                id:this.props.match.params.key,
                counm:this.state.hersharecounm,
            },
            props:this.props,
            disloading:true,
            }).then((data)=>{
            if(data){
                this.setState({heishareinitLoading:false});
                if(data.msg){
                    message.error(data.msg);
                }
                console.log(data);
            }
            
            });
    }
    showconment(conment){
        conment=decrypt(conment);
         // ----------------------- editor config -----------------------
        const editorConfig = {}
        editorConfig.placeholder = '加载中...';
        editorConfig.height='auto';
        editorConfig.onCreated = (editor) => {
        editor.disable()
        }
        this.setState({
            conment:<Editor
            defaultConfig={editorConfig}
            defaultHtml={conment}
            mode="default"
            />,
            conmentheadershow:true,
        });
    }
    initf(data){
        if(data){
            if(data.user){
                let con=this.state.userinfo;
                con.username=data.user.username;
                con.img=data.user.imgname;
                con.lasttime=data.user.lastlogintime;
                con.like=data.user.likenum;
                con.dislike=data.user.dislikenum;
                this.setState({
                    userinfo:con,
                });
            }
            if(data.overdue){
                this.setState({
                    overdue:data.overdue,
                    conment:<Empty style={{"marginTop":'20%'}} description={"来晚了，分享的文件过期了！"}/>
                });

            }else if(data.pwd){
                let conment=localStorageGet('note_basedate_conment'+this.props.match.params.key);
                if(!conment){
                    const con=<Empty style={{"marginTop":'20%'}} description={"该文档需要提取码"}/>
                    this.setState({
                        conment:con,
                        modalvisible:true,
                    });
                }else{
                    this.showconment(conment);
                }
                
            }else{
                this.getconment();
            }
            if(data.sharenote){
                let con=this.state.shareinfo;
                con.title=data.sharenote.title;
                con.username=data.sharenote.username;
                con.createtime=data.sharenote.createtime;
                con.like=data.sharenote.likenum;
                con.dislike=data.sharenote.dislikenum;
                con.view=data.sharenote.viewnum;
                this.setState({shareinfo:con});
            }
            this.setState({
                baseinfloading:false,
            });
        }
        let like=localStorageGet('note_basedate_like'+this.props.match.params.key);
        if(like){
            this.setState(like);
        }
        let likenote=localStorageGet('note_basedate_likenote'+this.props.match.params.key);
        if(likenote){
            this.setState(likenote);
        }
        
    }
    componentDidMount(){
        let data=localStorageGet('note_basedate_user'+this.props.match.params.key);
        if(!data){
            request({
                url:baseurl.share.getbaseinfo,
                method:'post',
                data:{
                    id:this.props.match.params.key
                },
                props:this.props,
              }).then((basedata)=>{
                console.log(basedata);
                if(basedata){
                    data=basedata;
                    localStorageSet("note_basedate_user"+this.props.match.params.key,data);
                    this.initf(data);
                }
              });
        }else{
            this.initf(data);
        }
    }
    render(){
        const like = () => {
            if(this.state.onclicklike===""){
                let con=this.state.userinfo;
                con.like=con.like+1;
                this.setState({
                    userinfo:con,
                    onclicklike:"liked",
                });
                let res={
                    userinfo:con,
                    onclicklike:"liked",
                }
                localStorageSet("note_basedate_like"+this.props.match.params.key,res);
                request({
                    url:baseurl.share.like,
                    method:'post',
                    data:{
                        id:this.props.match.params.key,
                        type:"likeuser"
                    },
                    disloading:true,
                    props:this.props,
                    }).then((data)=>{
                    });
            }
            
          };
        
          const dislike = () => {
            if(this.state.onclicklike===""){
                let con=this.state.userinfo;
                con.dislike=con.dislike+1;
                this.setState({
                    userinfo:con,
                    onclicklike:"disliked",
                });
                let res={
                    userinfo:con,
                    onclicklike:"disliked",
                }
                localStorageSet("note_basedate_like"+this.props.match.params.key,res);
                request({
                    url:baseurl.share.like,
                    method:'post',
                    data:{
                        id:this.props.match.params.key,
                        type:"dislikeuser"
                    },
                    disloading:true,
                    props:this.props,
                    }).then((data)=>{
                    });
            }
          };
          const likenote = () => {
            if(this.state.onclicknotelike===""){
                let con=this.state.shareinfo;
                con.like=con.like+1;
                this.setState({
                    shareinfo:con,
                    onclicknotelike:"liked",
                });
                let res={
                    shareinfo:con,
                    onclicknotelike:"liked",
                }
                localStorageSet("note_basedate_likenote"+this.props.match.params.key,res);
                request({
                    url:baseurl.share.like,
                    method:'post',
                    data:{
                        id:this.props.match.params.key,
                        type:"likenote"
                    },
                    disloading:true,
                    props:this.props,
                    }).then((data)=>{
                    });
            }
            
          };
        
          const dislikenote = () => {
            if(this.state.onclicknotelike===""){
                let con=this.state.shareinfo;
                con.dislike=con.dislike+1;
                this.setState({
                    shareinfo:con,
                    onclicknotelike:"disliked",
                });
                let res={
                    shareinfo:con,
                    onclicknotelike:"disliked",
                }
                localStorageSet("note_basedate_likenote"+this.props.match.params.key,res);
                request({
                    url:baseurl.share.like,
                    method:'post',
                    data:{
                        id:this.props.match.params.key,
                        type:"dislikenote"
                    },
                    disloading:true,
                    props:this.props,
                    }).then((data)=>{
                    });
            }
          };
          const data = [
            {
              title: 'Ant Design Title 1',
            },
            {
              title: 'Ant Design Title 2',
            },
            {
              title: 'Ant Design Title 3',
            },
            {
              title: 'Ant Design Title 4',
            },
          ];
          const heishareloadMore =
            !this.state.heishareinitLoading && !this.state.heishareloading ? (
            <div
                style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
                }}
            >
                <Button size='small' >加载更多</Button>
            </div>
            ) : null;


        return (
                <div>
                    <div className='header' style={{'width':'100%','minHeight':64,'backgroundColor':'#001529',}}>
                        <div className="logo" >
                            <img src="/notelogo.png" width="60px" height="60px" alt="LOGO"/>
                            <span style={{color:'white',fontSize:25}}>云笔记</span>
                        </div>
                    </div>
                    <div className='main' style={{padding:'0px 40px','marginTop':20,width:'100%'}}>
                        <div className='sider' 
                            ref={this.siderdiv}
                            style={{
                            'width':'20%',
                            'display':'inline-block',
                            'position':'sticky',
                            'top':0,
                            'verticalAlign': 'top',
                            }}>
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                display: 'flex',
                                }}
                            >
                                <Card
                                    style={{
                                    width: '100%',
                                    }}
                                    actions={[
                                        <Tooltip key="comment-basic-like" title="点个赞吧">
                                        <span onClick={like}>
                                        {this.state.onclicklike === 'liked' ? <LikeFilled/>: <LikeOutlined/>}
                                        <span className="comment-action">{this.state.userinfo.like}</span>
                                        </span>
                                    </Tooltip>,
                                    <span onClick={dislike}>
                                    {this.state.onclicklike === 'disliked' ? <DislikeFilled/> : <DislikeOutlined/>}
                                    <span className="comment-action">{this.state.userinfo.dislike}</span>
                                    </span>,
                                    // <span key="comment-basic-reply-to" onClick={()=>this.onclickhershare()}>他的分享</span>,
                                    ]}
                                    loading={this.state.baseinfloading}
                                >
                                    <Meta
                                    avatar={<Avatar src={'/img/userimg/'+this.state.userinfo.img+'.png'} />}
                                    title={this.state.userinfo.username}
                                    description={"上次在线："+this.state.userinfo.lasttime}
                                    />
                                </Card>
                                <Card
                                    style={{
                                    width: '100%',
                                    display:this.state.Sharelistdisplay
                                    }}
                                    loading={false}
                                >
                                    <List
                                        size="small"
                                        itemLayout="horizontal"
                                        dataSource={data}
                                        loading={this.state.heishareinitLoading}
                                        loadMore={heishareloadMore}
                                        renderItem={(item) => (
                                        <List.Item
                                        // actions={[<span><LikeOutlined/>1</span>, <span><DislikeOutlined/>1</span>]}
                                        >   <Skeleton  title={false} loading={this.state.heishareinitLoading} active>
                                                <List.Item.Meta
                                                // avatar={<Avatar src="./img/userimg/Gululu(6).png" />}
                                                title={<a href="https://ant.design">{item.title}</a>}
                                                // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                                />
                                            </Skeleton>
                                        </List.Item>
                                        )}
                                    />
                                </Card>
                                <Card
                                    style={{
                                    width: '100%',
                                    }}
                                    cover={
                                        <img
                                        alt="example"
                                        src="https://note.yiqiulove.cn/NoteServer/filedownload/b4450587-1545-44db-af7e-fc77e4b05455.jpg"
                                        />
                                    }
                                    actions={[
                                        <span key="comment-basic-reply-to" ><a href="https://curl.qcloud.com/vS4t0xEA">前往选购</a></span>,
                                    ]}
                                >
                                    <span>【腾讯云】多款云产品1折起，买云服务器送免费机器，最长免费续3个月</span>
                                </Card>
                                <Card
                                    style={{
                                    width: '100%',
                                    }}
                                    cover={
                                        <img
                                        alt="example"
                                        src="https://note.yiqiulove.cn/NoteServer/filedownload/218ca390-b31a-46cf-8510-a34725acca8d.jpg"
                                        />
                                    }
                                    actions={[
                                        <span key="comment-basic-reply-to" ><a href="https://curl.qcloud.com/654MIHnH">前往选购</a></span>,
                                    ]}
                                >
                                    <span>【腾讯云】爆款云服务器限时体验20元起，更多上云必备产品低至1元</span>
                                </Card>
                            </Space>
                        </div>
                        <div className='content' style={{
                            'width':'79%',
                            'height':'auto',
                            'display':'inline-block',
                            'verticalAlign': 'top',
                            'marginLeft':5}}>
                            {this.state.conmentheadershow ? <Comment
                                    style={{'backgroundColor':'#ffffff'}}
                                    actions={[
                                        <span onClick={likenote}>
                                        {this.state.onclicknotelike === 'liked' ? <LikeFilled/>: <LikeOutlined/>}
                                        <span className="comment-action">{this.state.shareinfo.like}</span>
                                        </span>,
                                        <span onClick={dislikenote}>
                                        {this.state.onclicknotelike === 'disliked' ? <DislikeFilled/> : <DislikeOutlined/>}
                                        <span className="comment-action">{this.state.shareinfo.dislike}</span>
                                        </span>,
                                        <span><EyeOutlined /><span className="comment-action">{this.state.shareinfo.view}</span></span>
                                      ]}
                                    author={<span>{this.state.shareinfo.username}</span>}
                                    avatar={<Avatar src={'/img/userimg/'+this.state.userinfo.img+'.png'} />}
                                    content={
                                        <h2 >{this.state.shareinfo.title}</h2>
                                    }
                                    datetime={
                                        <span>发表于：{this.state.shareinfo.createtime}</span>
                                    }
                                    />:null}
                            {this.state.conment}
                        </div>
                        <Modal
                            title="提取码"
                            visible={this.state.modalvisible}
                            footer={null}
                            closable={false}
                            style={{
                                top: 40,
                              }}
                            >
                            <Input.Group>
                                <Input allowClear={true} value={this.state.pwd} onChange={
                                ( { target: { value }})=>{
                                    let pa=/^[\w]{0,8}$/g;
                                    if(pa.test(value)){
                                    this.setState({pwd:value});
                                    }
                                }
                                }
                                placeholder="请输入提取码"
                                size="large"
                                style={{
                                width: 'calc(100% - 200px)',
                                }}
                                />
                                <Button type="primary"  size="large"   onClick={this.getconment}>确定</Button>
                            </Input.Group>
                        </Modal>
                    </div>
                    <div className='footer' style={{ textAlign: 'center',width:'100%',height:'60px','paddingTop':'20px' ,}}>
                    yiqiulove.cn ©2022 Created by yiqiu <a href="https://beian.miit.gov.cn">皖ICP备2021012075号</a> 
                    <BackTop duration={800} visibilityHeight={200}/>      
                    </div>
                </div>
                
                )
    }
}
export default Share;