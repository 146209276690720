import React from "react";
import "antd/dist/antd.css";
import "./css/liveguanli.css";

import { Table,Space, Input,Form ,Select,Divider,Empty,Descriptions} from 'antd';
import { Modal, Button,message } from 'antd';
import {
    DeleteOutlined,
    YoutubeOutlined,
    ApiOutlined
  } from '@ant-design/icons';

import baseurl from "../baseurl";
import { request } from "../util/helper";
import { localStorageSet,localStorageGet} from '../../public/util/localStorageutil';



class Liveguanli extends React.Component{
    constructor(props){
        super();
        this.state={
            data:[],
            visible:false,
            addlivevisible:false,
            queryliveloading:false,
            confirmLoading:false,
            liveinfoshowflag:false,
            liveinfo:{},
            title:'',
            handleOkf:null,
            modalconment:"",
            newptname:"",
            newroomid:"",
            newcookie:"",
            newselectqx:""
        }
        this.stoplive=this.stoplive.bind(this);
        this.querylivehandleOk=this.querylivehandleOk.bind(this);
        this.liveload=this.liveload.bind(this);
        this.getalllive=this.getalllive.bind(this);
    }
    componentDidMount(){
        this.getalllive();
    }
    querystoplive(key){
        this.setState({
          handleOkf:()=>{
            this.stoplive(key)
          },
          visible:true,
          title:'停止录制',
          modalconment:<div>你确定要停止录制 <strong>{key}</strong>吗？</div>
        });
    }
    stoplive(key){
        console.log(key);
        request({
            url:baseurl.admin.stopRecord,
            method:'post',
            props:this.props.parentprops,
            data:{
              "key":key,
            },
          }).then((res)=>{
                this.getalllive();
                this.setState({
                    visible:false
                });
          });
    }
    liveload(){
        if(!this.state.liveinfoshowflag){
            message.error("请先查询直播");
            return;
        }
        if(this.state.liveinfo.liveStatus!==1){
            message.error("主播未在直播");
            return;
        }
        if(!this.state.newselectqx){
            message.error("请选择清晰度");
            return;
        }
        request({
            url:baseurl.admin.startliveload,
            method:'post',
            props:this.props.parentprops,
            data:{
              "pt":this.state.newptname,
              "roomid":this.state.newroomid,
              "cookie":this.state.newcookie,
              "qx":this.state.newselectqx,
            },
          }).then((res)=>{
                this.getalllive();
                this.setState({
                    addlivevisible:false
                });
          });
    }
    getalllive(){
        request({
            url:baseurl.admin.getalllive,
            method:'post',
            props:this.props.parentprops,
            disloading:true
          }).then((res)=>{
            if(res && res.list){
                console.log(res)
                this.setState({
                    data:res.list
                });
            }else{
                this.setState({
                    data:[]
                });
            }
          }); 
    }
    querylivehandleOk(){
      console.log(this.state);
      request({
        url:baseurl.admin.queryliveInfo,
        method:'post',
        props:this.props.parentprops,
        data:{
          "pt":this.state.newptname,
          "roomid":this.state.newroomid,
          "cookie":this.state.newcookie,
        },
      }).then((res)=>{
            if(res){
                localStorageSet("ptcookie_"+this.state.newptname,this.state.newcookie);
                this.setState({
                    liveinfo:res,
                    liveinfoshowflag:true
                });
            }else{
                this.setState({
                    liveinfo:{},
                    liveinfoshowflag:false
                });
            }
      });
    }

    render(){
      const columns = [
        {
          title: 'key',
          width:'10%',
          dataIndex: 'key',
          key: 'key',
        //   render: text => <a>{text}</a>,
        },
        {
            title: '平台',
            width:'5%',
            dataIndex: 'livetype',
            key: 'livetype',
        },
        {
            title: '房间号',
            width:'10%',
            dataIndex: 'roomId',
            key: 'roomId',
        },
        {
            title: '录制清晰度',
            width:'10%',
            dataIndex: 'selectqx',
            key: 'selectqx',
        },
        {
          title: '主播名称',
          width:'10%',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
            title: '描述',
            width:'10%',
            dataIndex: 'description',
            key: 'description',
          },
        {
            title: '状态',
            width:'10%',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '下载大小',
            width:'10%',
            dataIndex: 'downloadedFileSize',
            key: 'downloadedFileSize',
        },
        {
            title: '下载位置',
            width:'10%',
            dataIndex: 'filepath',
            key: 'filepath',
        },
        {
          title: '操作',
          key: 'action',
          render: (record) => {
            return(
                <Space size="middle">
                    <Button type="primary" icon={<DeleteOutlined />} onClick={()=>this.querystoplive(record.key)} size="small" >停止录制</Button>
                </Space>
            );
            
          },  
        },
      ];

        return (<div><Table
            columns={columns}
            dataSource={this.state.data}
            bordered
            title={() =>{
                return <div>
                        <Button type="primary" icon={<YoutubeOutlined />} size="small" shape="round" onClick={()=>this.setState({addlivevisible:true})}>新增录制</Button>
                        <span> </span>
                        <Button type="primary" size="small" shape="round" onClick={()=>this.getalllive()}>刷新</Button>
                    </div>
            }}
          />
          <Modal
            title={this.state.title}
            open={this.state.visible}
            onOk={this.state.handleOkf}
            confirmLoading={this.state.confirmLoading}
            onCancel={()=>{
              this.setState({
                visible:false
              });
            }}
          >
            {this.state.modalconment}
          </Modal>
          <Modal
            title="查询直播"
            width={1000}
            open={this.state.addlivevisible}
            onCancel={()=>{this.setState({addlivevisible:false})}}
            footer={[
              <Button key="back" onClick={()=>{this.setState({addlivevisible:false})}}>
                取消
              </Button>,
              <Button key="ok" type="primary" onClick={()=>this.liveload()}>
                录制直播
              </Button>
            ]}
          >
            <Form
                layout={"inline"}
                >
                <Form.Item label="平台">
                    <Select
                        showSearch
                        placeholder="请选择录制平台"
                        optionFilterProp="children"
                        onChange={(value)=>{
                            debugger
                            let cookie=localStorageGet("ptcookie_"+value)
                            this.setState({
                                newptname:value,
                                newcookie:cookie
                            });
                        }}
                        filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                        {
                            value: 'douyu',
                            label: '斗鱼',
                        },
                        {
                            value: 'kuaishou',
                            label: '快手-需cookie',
                        },
                        {
                            value: 'douyin',
                            label: '抖音-需cookie',
                        },
                        {
                            value: 'douyin2',
                            label: '抖音2-需cookie',
                        },
                        {
                            value: 'yy',
                            label: 'YY-需cookie',
                        },
                        {
                            value: 'huya',
                            label: '虎牙',
                        },
                        {
                            value: 'huya2',
                            label: '虎牙2',
                        },
                        {
                            value: 'acfun',
                            label: 'acfun',
                        },
                        {
                            value: 'bili',
                            label: '哔哩哔哩',
                        },
                        {
                            value: 'zhanqi',
                            label: '战旗',
                        },
                        {
                            value: 'huajiao',
                            label: '花椒',
                        },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="房间号">
                    <Input
                        value={this.state.newroomid}
                        prefix={<YoutubeOutlined className="site-form-item-icon" />}
                        onChange={({ target: { value }})=>{this.setState({newroomid:value})}}
                    />
                </Form.Item>
                <Form.Item label="cookie">
                    <Input
                        value={this.state.newcookie}
                        prefix={<ApiOutlined className="site-form-item-icon" />}
                        onChange={({ target: { value }})=>{this.setState({newcookie:value})}}
                    />
                </Form.Item>
                <Form.Item>
                    <Button  type="primary" loading={this.state.queryliveloading} onClick={()=>this.querylivehandleOk()}>
                        搜索直播
                    </Button>
                </Form.Item>
            </Form>
            <Divider/>
            {this.state.liveinfoshowflag? 
            <dev>
                <Descriptions title="直播信息">
                    <Descriptions.Item label="userName">{this.state.liveinfo.userName}</Descriptions.Item>
                    <Descriptions.Item label="roomId">{this.state.liveinfo.roomId}</Descriptions.Item>
                    <Descriptions.Item label="description">{this.state.liveinfo.description}</Descriptions.Item>
                    <Descriptions.Item label="liveStatus">{this.state.liveinfo.liveStatus===1?
                     '正在直播':this.state.liveinfo.liveStatus===0?'未在直播':''}</Descriptions.Item>
                </Descriptions>
                {this.state.liveinfo.liveStatus===1?
                <div>
                    <Divider/>
                    <Form
                    layout={"inline"}
                    >
                    <Form.Item label="清晰度选择">
                    <Select
                        showSearch
                        placeholder="请选择录制清晰度"
                        optionFilterProp="children"
                        onChange={(value)=>{this.setState({newselectqx:value})}}
                        filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.liveinfo.qualitylist.map((value)=>{
                            let qx=value.split('-')
                            return {
                                value: value,
                                label: qx[1],
                            }
                        })}
                    />
                    </Form.Item>
                    </Form>
                </div>
                :null}
                
            </dev>
            :<Empty description={"请先查询"} />}
          </Modal>
          </div>
          );
    }
}
export default Liveguanli;