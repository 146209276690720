const baseurl={
    "login":{
        "loginon":"/NoteServer/login/loginon/",
        "loginout":"/NoteServer/login/loginout/",
        "updatepwd":"/NoteServer/login/updatepwd/",
        "kaptcha":"/NoteServer/login/defaultKaptcha/"
    },
    "sso":{
        "getSsoAuthUrl":"/NoteServer/sso/getSsoAuthUrl/",
        "checkTicket":"/NoteServer/sso/checkTicket/"
    },
    "main":{
        "baseinf":"/NoteServer/main/baseinf",
        "getconmentforkey":"/NoteServer/main/getconmentforkey",
        "newnote":"/NoteServer/main/newnote",
        "saveconment":"/NoteServer/main/saveconment",
        "deleteconment":"/NoteServer/main/deleteconment",
        "getpwd":"/NoteServer/main/getpwd",
        "setsharenote":"/NoteServer/main/setsharenote",
        "getsharelist":"/NoteServer/main/getsharelist",
        "deletesharelist":"/NoteServer/main/deletesharelist",
    },
    "share":{
        "getbaseinfo":"/NoteServer/quickurl/getbaseinfo",
        "getconment":"/NoteServer/quickurl/getconment",
        "like":"/NoteServer/quickurl/like",
        "gethersharelist":"/NoteServer/quickurl/gethersharelist",
    },
    "admin":{
        "userinf":"/NoteServer/admin/userinf",
        "userlist":"/NoteServer/admin/userlist",
        "userreset":"/NoteServer/admin/userreset",
        "adduser":"/NoteServer/admin/adduser",
        "deleteuser":"/NoteServer/admin/deleteuser",
        "queryliveInfo":"/NoteServer/admin/getInfo",
        "startliveload":"/NoteServer/admin/startliveload",
        "getalllive":"/NoteServer/admin/getalllive",
        "stopRecord":"/NoteServer/admin/stopRecord",
    },
    "file":{
        "imgfile":"/NoteServer/file/uploadimg"
    }
}

export default baseurl;