import React from "react";
import "antd/dist/antd.css";

import { Table,Space, Input } from 'antd';
import { Modal, Button } from 'antd';
import {
    EditFilled,
    DeleteOutlined,
    UserAddOutlined,
    // LockOutlined
  } from '@ant-design/icons';

import baseurl from "../baseurl";
import { request } from "../util/helper";



class Userguanli extends React.Component{
    constructor(props){
        super();
        this.state={
            data:[],
            visible:false,
            confirmLoading:false,
            title:'',
            handleOkf:null,
            modalconment:"",
            newusername:"",
        }

        this.Initialize=this.Initialize.bind(this);
        this.updatepwd=this.updatepwd.bind(this);
        this.updatepwdhandleOk=this.updatepwdhandleOk.bind(this);
        this.adduser=this.adduser.bind(this);
        this.adduserhandleOk=this.adduserhandleOk.bind(this);
    }
    componentDidMount(){
        this.Initialize();
    }
    Initialize(){
      request({
        url:baseurl.admin.userlist,
        method:'post',
        props:this.props.parentprops,
      }).then((res)=>{
        if(res){
          this.setState({
            data:res.list
          });
        }
      });
    }
    updatepwd(key){
      this.setState({
        handleOkf:()=>{
          this.updatepwdhandleOk(key)
        },
        visible:true,
        title:'密码重置',
        modalconment:"你确定要重置密码吗？"
      });
    }
    updatepwdhandleOk(key){
      this.setState({
        confirmLoading:true,
      });
      request({
        url:baseurl.admin.userreset,
        method:'post',
        props:this.props.parentprops,
        data:{
          "key":key
        },
      }).then((res)=>{
        if(res&&res.newpwd){
          this.setState({
            modalconment:<div>密码重置成功，新密码为:<strong>{res.newpwd}</strong></div>,
            confirmLoading:false,
            handleOkf:()=>{
               this.setState({
                  visible:false
                });
            }
          });
        }
      });

    };
    adduser(){
      this.setState({
        handleOkf:()=>{
          this.adduserhandleOk()
        },
        visible:true,
        title:'新增',
        modalconment:<div><label>新用户名</label><Input
        prefix={<UserAddOutlined className="site-form-item-icon" />}
        onChange={({ target: { value }})=>{
          this.setState({
            newusername:value
          });
        }}
      /></div>
      });
    }
    adduserhandleOk(){
      console.log(this.state.newusername);
      request({
        url:baseurl.admin.adduser,
        method:'post',
        props:this.props.parentprops,
        data:{
          "newusername":this.state.newusername
        },
      }).then((res)=>{
        if(res&&res.newpwd){
          this.setState({
            modalconment:<div>新增用户  <strong>{this.state.newusername}</strong> 成功，初始密码为: <strong>{res.newpwd}</strong></div>,
            confirmLoading:false,
            handleOkf:()=>{
              this.Initialize();
               this.setState({
                  visible:false
                });
            }
          });
        }
      });
    }
    deleteuser(key){
      this.setState({
        handleOkf:()=>{
          this.deleteuserhandleOk(key)
        },
        visible:true,
        title:'删除',
        modalconment:<div>你确定要删除用户 <strong>{key}</strong>吗？</div>
      });
    }
    deleteuserhandleOk(key){
      request({
        url:baseurl.admin.deleteuser,
        method:'post',
        props:this.props.parentprops,
        data:{
          "key":key
        },
      }).then((res)=>{
        if(res&&res.state){
          this.setState({
            modalconment:<div>用户 <strong>{key}</strong>删除成功</div>,
            confirmLoading:false,
            handleOkf:()=>{
              this.Initialize();
               this.setState({
                  visible:false
                });
            }
          });
        }
      });
    }

    render(){
      const columns = [
        {
          title: '用户名',
          width:'40%',
          dataIndex: 'name',
          key: 'name',
        //   render: text => <a>{text}</a>,
        },
        {
          title: '最近登录时间',
          width:'40%',
          dataIndex: 'logintime',
          key: 'logintime',
        },
        {
          title: '操作',
          key: 'action',
          render: (record) => {
            return(
                <Space size="middle">
                    <Button type="primary" icon={<DeleteOutlined />} onClick={()=>this.deleteuser(record.key)} size="small" >删除</Button>
                    <Button type="primary" icon={<EditFilled />} onClick={()=>this.updatepwd(record.key)} size="small">重置密码</Button>
                </Space>
            );
            
          },  
        },
      ];

        return (<div><Table
            columns={columns}
            dataSource={this.state.data}
            bordered
            title={() =>{
                return <Button type="primary" icon={<UserAddOutlined />} size="small" shape="round" onClick={()=>this.adduser()}>新增</Button>
            }}
          />
          <Modal
        title={this.state.title}
        open={this.state.visible}
        onOk={this.state.handleOkf}
        confirmLoading={this.state.confirmLoading}
        onCancel={()=>{
          this.setState({
            visible:false
          });
        }}
      >
        {this.state.modalconment}
      </Modal>
          </div>
          );
    }
}
export default Userguanli;