import { message} from 'antd';
import {localStorageGet,localStorageSet} from "./localStorageutil"


let props 
const key = 'updatable';

function parseJSON(response){
    return response.json()
  }
  
  function checkStatus(response){
    if(response.status === 200){
      return response
    }
    message.error({ content: response.status+" "+response.statusText, key, duration: 2 });
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
  /**
   * 请求
   * @param {*url,*method,*data} options 
   */
  export  function request(options = {}){
    // message.loading({ content: 'Loading...',duration: 0, key });
    const Authorization = localStorageGet('x-access-token');
    const {data,url,disloading} = options
    options = {...options}
    delete options.url;
    props=options.props;
    delete options.props;
    if(data){
      delete options.data
      options.body = JSON.stringify(data)
    }
    if(!disloading){
      message.loading({ content: 'Loading...',duration: 0, key });
    }
    options.headers={
      'x-access-token':Authorization,
      'Content-Type':'application/json;charset=UTF-8'
    }
    return fetch(url,options,{credentials: 'include'})
      .then(checkStatus)
      .then(parseJSON)
      .then((res)=>{
        if(res.state ===200){
          message.destroy(key);
          return res.dateObject
        }
        else if(res.state === 500){
          message.error({ content: res.msg, key, duration: 2 });
          return ;
        }
        else if(res.state === 302){
            if(res.dateObject&&res.dateObject.token){
                // localStorage.setItem('x-access-token',res.token);
                localStorageSet("x-access-token",res.dateObject.token);
            }
            props.history.replace({pathname:res.url,data:res.dateObject});
            if(res.msg){
              message.info({ content: res.msg, key, duration: 2 });
            }else{
              message.destroy(key);
            }
          return res.dateObject;
        }else{
          return ;
        }
        
      })
      .catch(err=>({err}))
  }