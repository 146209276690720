import React from 'react';
import "antd/dist/antd.css";

import {Result} from 'antd';

class NOpage extends React.Component{
    constructor(props){
        super();
    }
    render(){
        return <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    // extra={<Button type="primary">Back Home</Button>}
                />
    }
}

export default NOpage;