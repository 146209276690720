import React  from 'react';
import '../index.css';
import "antd/dist/antd.css";
import "./main.css";
import '@wangeditor/editor/dist/css/style.css'
import { Layout, Menu } from 'antd';
import { Button ,Form,Segmented,Table,Space,Tooltip,Dropdown,Tabs } from 'antd';
import { Input,message ,Modal,Avatar,Popover,Select,BackTop,Switch } from 'antd';
import { encrypt,decrypt } from '../public/util/desutil';
import copy from 'copy-to-clipboard';
import {
  CopyOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  AppstoreOutlined, 
  EditTwoTone,
  RedoOutlined,
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  ProfileOutlined,
  ShareAltOutlined,
  NumberOutlined,
  DownOutlined,
  UsergroupDeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import baseurl from '../public/baseurl';
import {request} from '../public/util/helper';
// import ReactWEditor from 'wangeditor-for-react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { localStorageclear, localStorageGet } from '../public/util/localStorageutil';

const { Header, Sider, Content ,Footer} = Layout;
const { SubMenu } = Menu;
// const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

// //是否自动更新标识
// let selfupdateflag=false;
// function setselfupdateflagtrue (){
//   selfupdateflag=true;
// }
// let debouncesetselfupdateflagtrue=debounce(setselfupdateflagtrue,5000);

class SiderDemo extends React.Component {
  constructor(props){
      super();
      this.state={
        TabsactiveKey:null,
        Tabsitems:[],
        Tabstype:"card",
        sharelistselectvalue:null,
        sharelist:null,
        editor:null,
        refreshdisable:true,
        saveoreditdisable:true,
        saveoreditflag:true,
        modalvisible:false,
        sharemodalvisible:false,
        sharelistmodalvisible:false,
        Modalinoutvalue:"",
        collapsed: false,
        menuitems:null,
        isLoaded: false,
        token:null,
        user:{},
        data:{},
        mbconment:{
          conment:"<p><span style='font-size: 32px; font-family: 华文楷体;'><em><strong>欢迎使用云笔记</strong></em></span></p>",
          title:"",
          version:0,
          fontstyle:{
            fontSize:25,
            color:'black',
          },
        },
        shareobj:{
          title:"",
          effectiveday:"",
          password:"",
          pushflag:true
        },
        selectitemprops:null,
      };
      this.editorRef = React.createRef();

      this.toggle=this.toggle.bind(this);
      this.baseinff=this.baseinff.bind(this);
      this.menuitemf=this.menuitemf.bind(this);
      this.itemclikf=this.itemclikf.bind(this);
      this.saveoreditf=this.saveoreditf.bind(this);
      this.TextAreachange=this.TextAreachange.bind(this);
      this.modalsetVisible=this.modalsetVisible.bind(this);
      this.newnotef=this.newnotef.bind(this);
      this.Modalinoutchange=this.Modalinoutchange.bind(this);
      this.titlechange=this.titlechange.bind(this);
      this.deleteitem=this.deleteitem.bind(this);
      this.showPromiseConfirm=this.showPromiseConfirm.bind(this);
      this.loginout=this.loginout.bind(this);
      this.updateconment=this.updateconment.bind(this);
      this.getpwd=this.getpwd.bind(this);
      this.setsharenote=this.setsharenote.bind(this);
      this.sharelistselect=this.sharelistselect.bind(this);
      this.getsharelist=this.getsharelist.bind(this);
      this.deletesharenote=this.deletesharenote.bind(this);
      this.TabsonChange=this.TabsonChange.bind(this);
      this.TabsonEdit=this.TabsonEdit.bind(this);
      this.updateMenu=this.updateMenu.bind(this);
  }
  componentDidMount() {
     this.baseinff();
  }
  TextAreachange(value){
    let com=this.state.mbconment;
    com.conment=value;
    this.setState({
      mbconment:com,
    },()=>{
      // if(selfupdateflag){
      //   this.saveconmentself();
      // }
    });
  }
  titlechange({ target: { value }}){
    let com=this.state.mbconment;
    com.title=value;
    this.setState({
      mbconment:com,
    });

  }
  Modalinoutchange({ target: { value }}){
    this.setState({
      Modalinoutvalue:value,
    });
  }
  saveoreditf(){
      let conmentsplit=encrypt(this.state.mbconment.conment);
      // conmentsplit= conmentsplit.replace(/\n/g,"%%!%!%%!%");
      request({
        url:baseurl.main.saveconment,
        method:'post',
        data:{
          "key":this.state.selectitemprops.key,
          "title":this.state.mbconment.title,
          "conment":conmentsplit,
          "version":this.state.mbconment.version,
        },
        props:this.props,
      }).then((data)=>{
        if(data){
          if(Boolean(data.res)){
            let com=this.state.mbconment;
            com.version=data.version;
            this.setState({
              mbconment:com,
            });
            message.success("保存成功！");
          }else{
            message.error("保存失败："+data.errormsg);
          }
        }
      });
  }
  toggle () {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  showPromiseConfirm(f=()=>{}) {
    confirm({
      title: '删除',
      icon: <ExclamationCircleOutlined />,
      content: '你确定要删除吗?',
      onOk() {
        f();
      },
      onCancel() {},
    });
  }
  deleteitem(){
    if(!this.state.refreshdisable){
      request({
        url:baseurl.main.deleteconment,
        method:'post',
        data:{
          "key":this.state.selectitemprops.key
        },
        props:this.props,
      }).then((data)=>{
        if(data){
          if(Boolean(data.res)){
            this.updateMenu();
            this.TabsonEdit(this.state.selectitemprops.key,null);
            message.success("删除成功！");
            return true;
          }else{
            message.error("删除失败："+data.msg);
            return false;
          }
        }
      });
    }
  }
  itemclikf(item){
    // selfupdateflag=false;
    // debouncesetselfupdateflagtrue();
    if(item){
      this.setState({
        selectitemprops:item,
        refreshdisable:false,
        saveoreditdisable:false,
      });
      request({
        url:baseurl.main.getconmentforkey,
        method:'post',
        data:{
          "key":item.key
        },
        props:this.props,
      }).then((data)=>{
        if(data&&data.res){
          let decconment=data.conment? decrypt(data.conment):"";
          // let decconment=data.conment;
          let com=this.state.mbconment;
          com.conment=decconment;
          com.title=data.title;
          com.fontstyle=data.fontstyle;
          com.version=data.version;
          this.setState({
            mbconment:com,
          },()=>this.updateconment(decconment));
          this.state.editor.enable();
          
          let Tabsitems=this.state.Tabsitems;
          let flag=true;
          for (let i in Tabsitems) {
            let element = Tabsitems[i];
            if(element.key===item.key){
              flag=false;
              break
            }
          }
          if(flag){
            this.setState({
              Tabsitems:[
                ...this.state.Tabsitems,
                {
                  label: com.title,
                  key: item.key,
                },
              ],
              TabsactiveKey:item.key
            },()=>{
              if(this.state.Tabsitems.length>1){
                this.setState({
                  Tabstype:'editable-card'
                });
              }
            })
          }else{
            this.setState({
              TabsactiveKey:item.key
            })
          }
          
        } 
      });
    }
}
updateconment(decconment){
  decconment=decconment.replace("<p><br></p>","").replaceAll("%%!%!%%!%","\n");
  // 全选
  this.state.editor.select([])
  // 删除选中内容
  this.state.editor.deleteFragment()
  this.state.editor.dangerouslyInsertHtml(decconment);
  
}
modalsetVisible(boolea){
  this.setState({
    modalvisible:boolea,
  });
}
newnotef(){
  if(this.state.Modalinoutvalue===""){
    message.warning("标题不能为空",1);
  }else{
    request({
      url:baseurl.main.newnote,
      method:'post',
      data:{
        "title":this.state.Modalinoutvalue
      },
      props:this.props,
    }).then((data)=>{
      if(data){
        if(Boolean(data.res)){
          this.updateMenu();
        }else{
          message.error("新建失败"+data.errormsg);
        }
      }
    });
  this.setState({
    modalvisible:false,
    Modalinoutvalue:"",
  });
  }
  
}
sharelistselect(){
  let menuitems=this.state.data.menuitems;
    if(menuitems&&menuitems!==null&&menuitems.length!==0){
      let jsxcoment=menuitems.map(menuitem => {
                        return menuitem.child.map(month=>{
                            return month.child.map(day=>{
                              return <Option value={day.key}>{day.title}</Option>
                            })
                        })
                  })
      return jsxcoment;
    }
}
getsharelist(){
  request({
    url:baseurl.main.getsharelist,
    method:'post',
    data:{"notekey":this.state.sharelistselectvalue},
    props:this.props,
  }).then((data)=>{
    if(data&&data.sharelist){
      this.setState({sharelist:data.sharelist});
    }
  });
}


  menuitemf(data){
    let menuitems=data.menuitems;
    let defaultOpenKeys=menuitems[0].key;
    if(menuitems&&menuitems!==null&&menuitems.length!==0){
      let jsxcoment=<Menu
      onClick={this.itemclikf}
      defaultOpenKeys={[""+defaultOpenKeys]}
      mode="inline"
    >
      {
        menuitems.map(menuitem => {
          return <SubMenu key={menuitem.key} icon={<AppstoreOutlined />} title={menuitem.title}>
            {
                menuitem.child.map(month=>{
                  return <Menu.ItemGroup key={month.key} title={month.title}>
                  {
                      month.child.map(day=>{
                        return <Menu.Item icon={<ProfileOutlined />} key={day.key}>{day.title}</Menu.Item>
                      })
                 }
                </Menu.ItemGroup>
                })
            }
        </SubMenu>
        })
      }
      </Menu>;
      return jsxcoment;

    }
    
  };
  loginout(){
    request({
      url:baseurl.login.loginout,
      method:'post',
      props:this.props,
    }).then(()=>{
     localStorageclear();
    });
  }
  getpwd(){
    request({
      url:baseurl.main.getpwd,
      method:'post',
      props:this.props,
    }).then((data)=>{
      if(data.pwd){
        let obj=this.state.shareobj;
        obj.password=data.pwd;
        this.setState({shareobj:obj});
      }
    });
  }
  deletesharenote(id){
    request({
      url:baseurl.main.deletesharelist,
      method:'post',
      data:{
        "id":id
      },
      props:this.props,
    }).then((data)=>{
      if(data&&data.res){
        this.getsharelist();
        // message.success("删除成功");
      }
      
    });
  }
  setsharenote(){
    if(this.state.shareobj.title===""){
      message.error("标题不能为空");
      return
    }
    let obj=this.state.shareobj;
    obj.key=this.state.selectitemprops.key
    request({
      url:baseurl.main.setsharenote,
      method:'post',
      data:obj,
      props:this.props,
    }).then((data)=>{
      if(data&&data.id){
        console.log(data.id);
        copy(window.location.protocol+'//'+window.location.host+'/quickurl/'+data.id);
        this.setState({
          sharemodalvisible:false,
          shareobj:{
            title:"",
            effectiveday:"",
            password:""
          }
        });
        message.success("已复制分享路径至剪贴板");
      }
    });
  }
  TabsonChange(key){
    let item={
      key:key
    }
    this.itemclikf(item);
  }
  TabsonEdit(targetKey, action){
    if (action === 'add') {
      // add();
    } else {
      let items=this.state.Tabsitems;
      const targetIndex = items.findIndex((pane) => pane.key === targetKey);
      const newPanes = items.filter((pane) => pane.key !== targetKey);

      if (newPanes.length && targetKey === this.state.TabsactiveKey) {
        const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
        let item={
          key:key
        }
        this.itemclikf(item);
      }

      if(newPanes.length<=1){
        this.setState({
          Tabstype:'card'
        });
      }

      this.setState({
        Tabsitems:newPanes
      })
      // remove(targetKey);
    }
  }
  updateMenu(){
    request({
      url:baseurl.main.baseinf,
      method:'post',
      props:this.props,
    }).then((data)=>{
      if(data){
        // com.title="";
        // com.conment="<p><span style='font-size: 32px; font-family: 华文楷体;'><em><strong>欢迎使用云笔记</strong></em></span></p>"
        this.setState({
          isLoaded: true,
          data: data,
          menuitems:this.menuitemf(data),
          user:data.user
        });
      }
  });   
      const {isLoaded } = this.state;
      if (!isLoaded) {
        this.setState({
          menuitems:<Menu key="1">
          <Menu.Item key="5" icon={<LoadingOutlined />}>菜单加载中。。。</Menu.Item>
    </Menu>,
        });
      }
  }
  baseinff(){
    request({
      url:baseurl.main.baseinf,
      method:'post',
      props:this.props,
    }).then((data)=>{
      if(data){
        let com=this.state.mbconment;
        // com.title="";
        // com.conment="<p><span style='font-size: 32px; font-family: 华文楷体;'><em><strong>欢迎使用云笔记</strong></em></span></p>"
        this.setState({
          isLoaded: true,
          data: data,
          menuitems:this.menuitemf(data),
          user:data.user,
          refreshdisable:true,
          saveoreditdisable:true
        });
        this.updateconment(com.conment);
      }
  });   
      const {isLoaded } = this.state;
      if (!isLoaded) {
        this.setState({
          menuitems:<Menu key="1">
          <Menu.Item key="5" icon={<LoadingOutlined />}>菜单加载中。。。</Menu.Item>
    </Menu>,
        });
      }
  }
  render() {
    // let conmentdiv = 
    // this.state.mbconment.conment.split("%%!%!%%!%").map((conment,key) => {
    //   if(conment==="")return <br key={key}></br>;
    //   else {
    //     let urlReg =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/i;
    //     let urlhtml=conment.replace(urlReg,"<a href='$1' className='conment_p' target='_blank'>$1</a>");
    //     return <p className="conment_p" key={key} dangerouslySetInnerHTML={{__html: 
    //       urlhtml}}></p>;
    //   }
    // });
    // ----------------------- editor config -----------------------
    const editorConfig = {}
    editorConfig.placeholder = '请输入内容...';
    editorConfig.height='auto';
    editorConfig.onCreated = (editor) => {
      this.setState({ editor })
      editor.disable()
      // console.log(editor.getAllMenuKeys());
    }
    editorConfig.onChange = (editor) => {
      this.TextAreachange(editor.getHtml());
    }
    editorConfig.MENU_CONF = {}
    editorConfig.MENU_CONF['uploadImage'] = {
      server: baseurl.file.imgfile, // 上传图片地址
      fieldName: 'file',
       // 单个文件的最大体积限制，默认为 2M
      maxFileSize: 10 * 1024 * 1024, // 10M

      // 最多可上传几个文件，默认为 100
      maxNumberOfFiles: 10,
      //base64LimitSize: 30 * 1024,// 30k 以下的转base64

      // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
      allowedFileTypes: ['image/*'],
      headers: {
        'x-access-token':localStorageGet('x-access-token'),
      },
      // 单个文件上传成功之后
    onSuccess(file, res) {
      // console.log(`${file.name} 上传成功`, res)
      message.success("上传成功");
    },
    // 单个文件上传失败
    onFailed(file, res) {
        // console.log(`${file.name} 上传失败`, res)
        message.error("上传失败"+res);
    },
    // 上传错误，或者触发 timeout 超时
    onError(file, err, res) {
      message.error("上传出错"+err);
        // console.log(`${file.name} 上传出错`, err, res)
    },
    }
    // 继续补充其他配置~

    // ----------------------- editor content -----------------------
    // const defaultContent = [
    //   { type: 'paragraph', children: [{ text: 'class 组件 - 基本使用' }] },
    //   { type: 'paragraph', children: [{ text: '' }] },
    // ]

    const defaultHtml = '<p><span style="font-size: 32px; font-family: 华文楷体;"><em><strong>欢迎使用云笔记</strong></em></span></p>';

    // ----------------------- toolbar config -----------------------
    const toolbarConfig = {
       excludeKeys: [
        'fullScreen',
        'uploadVideo', // 排除菜单组，写菜单组 key 的值即可
    ]
  }
  const columns = [
    {
      title: 'ID',
      width:'10%',
      dataIndex: 'notekey',
      key: 'notekey',
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    //   render: text => <a>{text}</a>,
    },
    {
      title: '提取码',
      width:'10%',
      dataIndex: 'pwd',
      key: 'pwd',
      ellipsis: {
        showTitle: false,
      },
      render: (address) => {
        if(address){
          return <Tooltip placement="topLeft" title={address}>
                  {address} <Button style={{"float":"right"}} type="text" shape="circle" size="small" icon={<CopyOutlined />} 
                  onClick={()=>{
                    copy(address);
                    message.success({ content: "已复制",  duration: 0.3 });
                  }} />
                </Tooltip>
        }else return "无"
        
          
      },
      // render: value => {
      //   if(!value.pwd){
      //     return <a>无</a>
      //   }else return value;
      // },
    },
    {
      title: '分享标题',
      width:'35%',
      dataIndex: 'title',
      key: 'title',
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    //   render: text => <a>{text}</a>,
    },
    {
      title: '有效期',
      width:'5%',
      dataIndex: 'day',
      key: 'day',
      ellipsis: {
        showTitle: false,
      },
      render: (address) => {
        if(address===0)address="永久";
        return <Tooltip placement="topLeft" title={address}>
                  {address}
                </Tooltip>},
    //   render: text => <a>{text}</a>,
    },
    {
      title: '创建时间',
      width:'10%',
      dataIndex: 'createtime',
      key: 'createtime',
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    //   render: text => <a>{text}</a>,
    },
    {
      title: '有效终止时间',
      width:'10%',
      dataIndex: 'endtime',
      key: 'endtime',
      ellipsis: {
        showTitle: false,
      },
      render: (address) => {
        if(!address)address="无";
        return <Tooltip placement="topLeft" title={address}>
                  {address}
                </Tooltip>},
    //   render: text => <a>{text}</a>,
    },
    {
      title: '操作',
      key: 'action',
      render: (record) => {
        return(
            <Space size="middle">
                <Button type="primary" icon={<CopyOutlined />} onClick={()=>{
                  copy(window.location.protocol+'//'+window.location.host+'/quickurl/'+record.id);
                  message.success({ content: "已复制分享路径至剪贴板",  duration: 0.5 });
                }} size="small" >链接</Button>
                <Button type="primary" icon={<DeleteOutlined />} onClick={()=>this.deletesharenote(record.id)} size="small">删除</Button>
            </Space>
        );
        
      },  
    },
  ];
  const othermenu=(
        <Menu
          items={[
            {
              label: <Button type="primary" icon={<EditTwoTone />} onClick={() => this.modalsetVisible(true)} size="small">新建笔记</Button>,
              key: '0',
            },
            {
              label: <Button type="primary" icon={<ShareAltOutlined />} size="small" onClick={()=>{this.setState({sharemodalvisible:false,sharelistmodalvisible:true,});
              this.getsharelist();}} >我的分享</Button>,
              key: '1',
            },
            {
              label: <Button type="primary" icon={<DeleteOutlined />} size="small" disabled={this.state.refreshdisable}
              onClick={()=>{this.showPromiseConfirm(()=>this.deleteitem())}}
             >删除笔记</Button>,
              key: '2',
            },
            {
              label: <Button type="primary" icon={<UsergroupDeleteOutlined />} size="small" disabled={this.state.refreshdisable}
              onClick={()=>{this.setState({sharemodalvisible:!this.state.sharemodalvisible})}}
             >分享笔记</Button>,
              key: '3',
            },
          ]}
        />
      );
    return (
      <Layout>
        <Sider 
        className="layoutroot" 
        trigger={null} 
        collapsible collapsed={this.state.collapsed} 
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        >
          <div className="logo">
            <img src="/notelogo.png" width="60px" height="60px" alt="LOGO"/>
            <span style={{color:'white',fontSize:25}}>云笔记</span>
          </div>
          {this.state.menuitems}
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: this.state.collapsed? 80:200}}>
          <Header className="site-layout-background" style={{ padding: 0 ,position:'sticky', top:0,zIndex:999}} >
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            <Popover placement="rightTop" title={this.state.user.username} content=
            {<div>
            <Avatar size={30} src={this.state.user.avatarurl} />
            <label style={{fontSize:'18px'}}>{this.state.user.nickname+'的个人云笔记'}</label>
            <p>{this.state.user.lastlogintime? '上次登录：'+this.state.user.lastlogintime:'欢迎使用云笔记'}</p>
            <Button type="link" onClick={()=>{window.location.href = decodeURIComponent('https://sso.yiqiulove.cn/me')}} >个人中心</Button>
            <Button style={{width:'100%',textAlign:"center"}} onClick={this.loginout}>退出登录</Button>
            </div>
            }>
              <Avatar size={50} src={this.state.user.avatarurl} />
            </Popover>
            <div style={{float:"right"}}>
              <Button type="primary" icon={<RedoOutlined />} size="middle" disabled={this.state.refreshdisable}
                onClick={()=>{this.itemclikf(this.state.selectitemprops)}}
               >刷新</Button><label> </label>
              <Button type="primary"  icon={<CheckOutlined/>} size="middle" 
              disabled={this.state.saveoreditdisable} onClick={()=>{this.saveoreditf()}}>保存</Button>
              <label> </label>
              <Dropdown overlay={othermenu} trigger={['click']}>
                <Button type="primary" icon={<DownOutlined />} size="middle" >更多</Button>
              </Dropdown>
            </div>
            <Toolbar
                editor={this.state.editor}
                defaultConfig={toolbarConfig}
                style={{ borderBottom: '1px solid #ccc' }}
              />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              marginTop:100,
              padding: 24,
              minHeight:'100vh',
            }}
          >
            <Tabs
              hideAdd
              animated={true}
              onChange={(key)=>{this.TabsonChange(key)}}
              activeKey={this.state.TabsactiveKey}
              type={this.state.Tabstype}
              onEdit={(targetKey, action)=>{this.TabsonEdit(targetKey, action)}}
              items={this.state.Tabsitems}
            />
            <div>
              <p><span style={{fontSize: 12}}></span><b><font size="5" face="楷体"><i>
              {this.state.mbconment.title}
              </i></font></b><span style={{fontSize: 12}}></span>
              </p>
              <Editor
                    defaultConfig={editorConfig}
                    // defaultContent={defaultContent}
                    defaultHtml={defaultHtml}
                    mode="default"
                />
            </div>
            <Modal
              title="起个简短的标题吧"
              centered
              visible={this.state.modalvisible}
              onOk={() => this.newnotef()}
              onCancel={() =>this.modalsetVisible(false)}
              width={1000}
            >
              <Input allowClear={true} value={this.state.Modalinoutvalue} onChange={this.Modalinoutchange}size="large"/>
            </Modal>
            <Modal
              title="分享"
              centered
              visible={this.state.sharemodalvisible}
              // onOk={() => this.newnotef()}
              onCancel={
                () =>this.setState({
                  sharemodalvisible:false,
                  shareobj:{
                    title:"",
                    effectiveday:"",
                    password:""
                  }
                })
              }
              // width={1000}
              footer={[
                <Button key="qx" type="primary"  onClick={
                  () =>{this.setState({
                    sharemodalvisible:false,
                    shareobj:{
                      title:"",
                      effectiveday:"",
                      password:"",
                      pushflag:true,
                    }
                  });
                }
                  }>
                  取消
                </Button>,
                <Button key="submit" type="primary" onClick={this.setsharenote}>
                  确定
                </Button>
              ]}
            >
              <Form.Item label="标题">
                <Input allowClear={true} value={this.state.shareobj.title} onChange={
                  ( { target: { value }})=>{
                    let obj=this.state.shareobj;
                    obj.title=value;
                    this.setState({shareobj:obj});
                  }
                }size="large"/>
              </Form.Item>
              <Form.Item label="有效期">
                <Segmented options={[
                        {
                          label: '1天',
                          value: '1',
                          icon: <NumberOutlined />,
                        },
                        {
                          label: '7天',
                          value: '7',
                          icon: <NumberOutlined />,
                        },
                        {
                          label: '永久',
                          value: '',
                          icon: <NumberOutlined />,
                        },
                      ]
                  } value={this.state.shareobj.effectiveday} onChange={(value)=>{
                    let obj=this.state.shareobj;
                    obj.effectiveday=value;
                    this.setState({shareobj:obj});
                  }} />
              </Form.Item>
              <Form.Item label="百度推送">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    onChange={(value)=>{
                      let obj=this.state.shareobj;
                      obj.pushflag=value;
                      this.setState({shareobj:obj});
                    }}/>
              </Form.Item>
              <Form.Item label="提取码">
              <Input.Group compact>
                  <Input allowClear={true} value={this.state.shareobj.password} onChange={
                    ( { target: { value }})=>{
                      // if(value.length<=8){
                      //   let obj=this.state.shareobj;
                      //   obj.password=value;
                      //   this.setState({shareobj:obj});
                      // }
                      let pa=/^[\w]{0,8}$/g;
                      if(pa.test(value)){
                        let obj=this.state.shareobj;
                        obj.password=value;
                        this.setState({shareobj:obj});
                      }
                    }
                  }
                  placeholder="为空则不设置提取码"
                  size="large"
                  style={{
                    width: 'calc(100% - 200px)',
                  }}
                  />
                  <Button type="primary"  size="large"   onClick={this.getpwd}
                >随机</Button>
               </Input.Group>
              </Form.Item>
            </Modal>
            <Modal
              title="分享列表"
              centered
              visible={this.state.sharelistmodalvisible}
              // onOk={() => this.newnotef()}
              onCancel={() =>{this.setState({sharelistmodalvisible:false,sharelistselectvalue:null})}}
              width={"90%"}
              footer={null}
            >
               <Form.Item label="笔记">
               <Select
                  style={{
                    width: "80%",
                  }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={(value)=>this.setState({sharelistselectvalue:value},()=>this.getsharelist())}
                  value={this.state.sharelistselectvalue}
                >
                  {this.sharelistselect()}
                </Select>
              </Form.Item>
              <Table
                columns={columns}
                dataSource={this.state.sharelist}
                bordered
                size="small"
                pagination={{
                  pageSize: 10,
                  hideOnSinglePage:true,
                }}
                scroll={{
                  y: 240,
                }}
              />
            </Modal>
          </Content>
          <Footer>
          <h2 style={{textAlign:"center"}}><a href="https://beian.miit.gov.cn">皖ICP备2021012075号</a></h2>
          <BackTop duration={800} visibilityHeight={200}/>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
 export default SiderDemo;
 